<template>
  <div class="list-info">
    <top-bar :title="'活动'" :left="true"></top-bar>
    <van-tabs v-model="activeName" color="#387FF5">
      <van-tab name="0" title="全部活动">
        <div class="search">
          <input v-model="searchValue" type="search" value="搜索" placeholder="请输入活动标题" class="search-content"
            @keyup.enter="onSearch">
          <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
          <div class="search-btn" @click="onSearch">搜索</div>
        </div>

        <div class="filterBar">
          <van-row>
            <van-col span="8">
              <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">活动类型
                <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt=""
                  class="pull-icon">
              </p>
            </van-col>
            <van-col span="8">
              <p @click="beginDateShow = !beginDateShow" :style="{color:beginDateShow?'#387FF5':'#666666'}">
                {{selectDate.beginDate || '开始时间'}}
                <img :src="require(`@/assets/img/${beginDateShow?'pull-down-select':'pull-down'}.png`)" alt=""
                  class="pull-icon">
              </p>
            </van-col>
            <van-col span="8">
              <p @click="endDateShow = !endDateShow" :style="{color:endDateShow?'#387FF5':'#666666'}">
                {{selectDate.endDate || '结束时间'}}
                <img :src="require(`@/assets/img/${endDateShow?'pull-down-select':'pull-down'}.png`)" alt=""
                  class="pull-icon">
              </p>
            </van-col>
          </van-row>
        </div>
        <activity-type v-show="typeShow" :list="typeList" @selectLabel="selectLabel"></activity-type>
        <!-- <van-popup v-model="typeShow" position="bottom"  >
          <van-picker title="标题" cancel-button-text="重置" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfim" @cancel="typeCancel" />
        </van-popup> -->
        <van-popup v-model="beginDateShow" position="bottom">
          <van-datetime-picker @confirm="beginDateConfim" @cancel="beginTimeCancel" type="date" title="选择年月日"
            :min-date="new Date(2017, 0, 1)" :max-date="new Date()" />
        </van-popup>
        <van-popup v-model="endDateShow" position="bottom">
          <van-datetime-picker @confirm="endDateConfim" @cancel="endTimeCancel" type="date" title="选择年月日"
            :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)" />
        </van-popup>

        <div class="cont" v-show="!typeShow">
          <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
            v-if="dataList.length > 0" offset="10">
            <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
            <div class="list-item" v-for="item in dataList" :key="item.id" @click="goinfo(item.id, item.putaway, item.needSign, item.createOrgId, item.surveyObject)">
              <div class="title">
                <div class="title-left" style="color:#387FF5">
                  {{item.typeStr}}
                </div>
                <div class="title-right" style="color:#ED4747">
                  {{item.surveyObjectStr + '活动'}}
                </div>
              </div>
              <div class="content">
                <van-image :src="item.imagePath || require('@/assets/img/flower.png')" class="content-img">
                  <template slot="error">
                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                  </template>
                  <template slot="loading">
                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                  </template>
                </van-image>
                <div class="content-text">
                  <div class="content-title">{{item.title}}</div>
                  <div class="apply" v-if="item.needSign != 1">活动人数：{{item.joinNum || '不限'}}人 无需报名</div>
                  <div class="apply" v-else-if="item.joinNum == 0">报名人数：<span class="num">不限</span> </div>
                  <div class="apply" v-else>报名人数：<span class="num">{{item.signNum}}人</span> 剩余：<span>{{item.joinNum -
                  item.signNum >= 0 ? item.joinNum - item.signNum : 0}}人</span></div>
                  <div class="time" v-if="item.progress == 3">{{item.signEndTime}} 报名截止 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 4" style="color: #F24C48">报名已结束 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 5" style="color: #FF7832">活动进行中 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 6" style="color: #F24C48">活动已结束</div>
                </div>
                <van-icon name="arrow" color="#666" class="content-icon" />
              </div>
              <div class="content-bottom">
                <div class="bottom-left">活动时间：{{item.beginTime}} 至 {{item.endTime}}</div>
              </div>
            </div>
          </van-list>
          <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          <!-- </van-pull-refresh> -->
        </div>
      </van-tab>
      <van-tab name="1" title="已报名活动">
        <div class="cont">
          <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
          <van-list :finished="true" finished-text="没有更多了" v-if="signDataList.length > 0" offset="10">
            <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{signTotalCount}}</span>&nbsp;条数据</div>
            <div class="list-item" v-for="item in signDataList" :key="item.id" @click="goinfo(item.id, item.putaway, item.needSign, item.createOrgId, item.surveyObject)">
              <div class="title">
                <div class="title-left" style="color:#387FF5">
                  {{item.typeStr}}
                </div>
                <div class="title-right" style="color:#ED4747">
                  {{item.surveyObjectStr + '活动'}}
                </div>
              </div>
              <div class="content">
                <van-image :src="item.imagePath || require('@/assets/img/flower.png')" class="content-img">
                  <template slot="error">
                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                  </template>
                  <template slot="loading">
                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                  </template>
                </van-image>
                <div class="content-text">
                  <div class="content-title">{{item.title}}</div>
                  <div class="apply" v-if="item.joinNum == 0">报名人数：<span class="num">不限</span> </div>
                  <div class="apply" v-else>报名人数：<span class="num">{{item.signNum}}人</span> 剩余：<span>{{item.joinNum -
                  item.signNum >= 0 ? item.joinNum - item.signNum : 0}}人</span></div>
                  <div class="time" v-if="item.progress == 3">{{item.signEndTime}} 报名截止 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 4" style="color: #F24C48">报名已结束 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 5" style="color: #FF7832">活动进行中 <div class="result" v-if="item.isSign">已签到</div><div class="result" v-else-if="item.sign">已报名</div></div>
                  <div class="time" v-if="item.progress == 6" style="color: #F24C48">活动已结束</div>
                </div>
                <van-icon name="arrow" color="#666" class="content-icon" />
              </div>
              <div class="content-bottom">
                <div class="bottom-left">活动时间：{{item.beginTime}} 至 {{item.endTime}}</div>
              </div>
            </div>
          </van-list>
          <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          <!-- </van-pull-refresh> -->
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { formatterDate } from '@/utils/utils'
import ActivityType from './activity-type'
import {getImageStream} from '@/utils'
export default {
  name: 'activity',
  components: {
    topBar,
    ActivityType
  },
  data() {
    return {
      activeName: '0',
      orgId: this.$orgId,
      searchValue: '',
      page: 0,
      limit: 10,
      totalCount: 0,
      signTotalCount: 0,
      beginDateShow: false,
      typeShow: false,
      loading: false,
      pullLoading: false,
      finished: false,
      endDateShow: false,
      dataList: [],
      signDataList: [],
      typeList: [],
      selectDate: {
        endDate: '',
        beginDate: '',
        type: ''
      }
    }
  },
  methods: {
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/nowList'),
        method: 'post',
        params: this.$http.adornParams({
          surveyObject: 1,
          putaway: 1,
          orgId: this.$orgId,        //用户组织结构id
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          title: this.searchValue,  //搜索条件
          type: this.selectDate.type,        //活动类型
          beginTime: this.selectDate.beginDate,   //活动开始时间
          endTime: this.selectDate.endDate,        //活动结束时间
          orgUserId: this.$globalData.userInfo.userId,
          logInOrgId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.clear()
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.totalCount = data.page.totalCount
          this.dataList = this.dataList.concat(data.page.list.map(item => {return {...item, imagePath: getImageStream(item.imagePath)}}))
          // 加载状态结束
          this.loading = false;
        } else {
          this.$toast.fail({
            duration: 1500,
            forbidClick: true,
            message: data.msg
          })
        }
      })
    },
    getMyList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/nowList'),
        method: 'post',
        params: this.$http.adornParams({
          surveyObject: 1,
          putaway: 1,
          page: 1,
          limit: -1,
          isSign: 1,
          orgId: this.$orgId,
          orgUserId: this.$globalData.userInfo.userId,
          logInOrgId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.signTotalCount = data.page.list.length
          this.signDataList = data.page.list.map(item => {return {...item, imagePath: getImageStream(item.imagePath)}})
        }
      })
    },
    selectLabel(e) {
      const { selectList } = e
      this.selectDate.type = selectList
      this.typeShow = false
      this.onPullDownRefresh()
    },
    // typeConfim (value) {
    //   this.page = 0
    //   this.typeStr = value.label
    //   this.selectDate.type = value.value
    //   this.dataList = []
    //   this.getDataList()
    //   this.typeShow = false
    // },
    getTypeOptions() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.typeList = data.dicts
        }
      }, err => { this.$toast.fail({ message: '活动类型获取失败' }) })
    },
    beginDateConfim(value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.page = 0
      this.dataList = []
      this.getDataList()
      this.beginDateShow = false
    },
    endDateConfim(value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.page = 0
      this.dataList = []
      this.getDataList()
      this.endDateShow = false
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    beginTimeCancel() {
      this.page = 0
      this.dataList = []
      this.selectDate.beginDate = ''
      this.beginDateShow = false
      this.getDataList()
    },
    endTimeCancel() {
      this.page = 0
      this.dataList = []
      this.selectDate.endDate = ''
      this.endDateShow = false
      this.getDataList()
    },
    goinfo(id) {
      this.$router.push({path: '/activity-info', query: {id}})
    }
  },
  created() {
    this.getTypeOptions()
    this.getDataList()
    this.getMyList()
  }
}
</script>

<style scoped lang="scss">
.content-img {
  width: 146px !important;
  height: 146px !important;
}

.content {
  height: inherit !important;
  padding: 24px 0;
  border-bottom: 1px solid #EBF2FE;

  .content-text {
    margin-left: 24px !important;
    padding: 10px 0;
    height: 146px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content-title {
    font-size: 28px;
  }

  .apply {
    font-size: 24px;
    font-weight: 400;
    color: #666666;

    .num {
      color: #333333;
    }
  }

  .time {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    color: #4581F8;

    .result {
      width: 84px;
      height: 40px;
      background: linear-gradient(135deg, #FFBE6A 0%, #FF853B 100%);
      border-radius: 8px;
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      margin-left: 24px;
      text-align: center;
    }
  }
}

.content-bottom {
  display: flex;
  justify-content: space-between;
  color: #999;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
}

.handle-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;

  // height: 120px;
  div {
    width: 47%;
    height: 56px;
    background: #387FF5;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 56px;
    text-align: center;

    &:nth-child(3) {
      margin-top: 10px;
    }

    &:nth-child(4) {
      margin-top: 10px;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  height: 100%;
  .block {
    width: 602px;
    min-height: 560px;
    background: #ffffff;
    border-radius: 20px;
    backdrop-filter: blur(0px);
    // padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .block-title {
      height: 92px;
      line-height: 92px;
      text-align: center;
      font-size: 32px;
      font-family: PingFang-Bold, PingFang SC;
      font-weight: 500;
      color: #373737;
    }
    .block-content {
      text-align: center;
      .queren {
        margin: 24px 0;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6225;
      }
    }
    .block-footer {
      .edit-handle {
        width: 602px;
        height: 102px;
        background: #FFFFFF;
        border-radius: 0px 0px 10px 10px;
        backdrop-filter: blur(20px);
      }
      .edit-btn {
        display: flex;
        .cancelbtn {
          width: 300px;
          height: 88px;
          border-radius: 0 0 0 20px;
        }
        .confirmbtn {
          width: 300px;
          height: 88px;
          font-weight: 400;
          color: #333333;
          font-size: 32px;
          font-family: 'PingFangSC-Regular';
          border-radius: 0 0 20px 0;
        }
      }

    }
  }
}
</style>
